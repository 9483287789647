import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './en.json';
import jaTranslations from './ja.json';

const getDefaultLanguage = () => {
  const browserLang = navigator.language;
  const browserLangs = navigator.languages;
  console.log('検出されたブラウザ言語:', browserLang);
  console.log('ブラウザの言語優先順位:', browserLangs);
  console.log('navigator.language:', navigator.language);
  console.log('navigator.userLanguage:', (navigator as any).userLanguage);
  console.log('navigator.browserLanguage:', (navigator as any).browserLanguage);
  console.log('navigator.systemLanguage:', (navigator as any).systemLanguage);
  return browserLang.split('-')[0] === 'ja' ? 'ja' : 'en';
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      ja: {
        translation: jaTranslations,
      },
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    lng: getDefaultLanguage(),
  });

i18n.on('languageChanged', (lng) => {
  console.log('言語が変更されました:', lng);
});

export default i18n;