import { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';

const Recruit: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <main className="max-w-4xl mx-auto px-10 py-10">
        <section className="text-black font-gothic text-center">
          <h2 className="text-2xl mb-6">{t("careers")}</h2>
          <div className="bg-white border border-gray-200 px-4 sm:px-6 md:px-10 lg:px-40 py-6 sm:py-8 md:py-10 rounded-xl border-2 border-black border-solid !border-opacity-100">
            <div className="mb-10">
              <h3 className="text-3xl mb-4">{t("roles")}</h3>
              <div className="mb-6">
                <p className="text-3xl mb-4 font-bold">{t("engineering")}</p>
                <ul className="list-none p-0">
                  <li>{t("product")}</li>
                  <li>{t("cloud")}</li>
                  <li>{t("webapp")}</li>
                  <li>{t("embedded")}</li>
                  <li>{t("machinelearning")}</li>
                  <li>{t("UI/UX")}</li>
                </ul>
              </div>
              <div className="mb-6">
                <p className="text-3xl mb-4 font-bold">{t("design")}</p>
                <ul className="list-none p-0">
                  <li>{t("parttimespecialist")}</li>
                </ul>
              </div>
              <div className="mb-6">
                <p className="text-3xl mb-4 font-bold">{t("businessmarketing")}</p>
                <ul className="list-none p-0">
                  <li>{t("businessdevelopment")}</li>
                  <li>{t("project")}</li>
                </ul>
              </div>
              <div className="mb-6">
                <p className="text-3xl mb-4 font-bold">{t("publicrelations")}</p>
                <ul className="list-none p-0">
                  <li>{t("businesspr")}</li>
                </ul>
              </div>
            </div>
            
            <div className="mb-10">
              <h3 className="text-3xl mb-4">{t("internships")}</h3>
              <p className="text-base leading-relaxed">
              {t("interns")}
              </p>
            </div>
            
            <div className="mb-10">
              <h3 className="text-3xl mb-4">{t("employmenttype")}</h3>
              <p>{t("type")}</p>
            </div>
            
            <div className="mb-10">
              <h3 className="text-3xl mb-4">{t("location")}</h3>
              <p>{t("fullyremote")}</p>
            </div>
            
            <div className="mb-10">
              <h3 className="text-3xl mb-4">{t("workinghours")}</h3>
              <p>{t("hours")}</p>
            </div>
            
            <div className="mb-10">
              <h3 className="text-3xl mb-4">{t("holidaysleave")}</h3>
              <p>{t("holidays")}</p>
            </div>
            
            <div className="mb-10">
              <h3 className="text-3xl mb-4">{t("benefits")}</h3>
              <p>{t("socialinsurance")}</p>
            </div>
            
            <div>
              <h3 className="text-3xl mb-4">{t("apply")}</h3>
              <p>{t("sendemail")}</p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Recruit;