import { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';

const Story: FunctionComponent = () => {
  const { t } = useTranslation();

  const renderSection = (title: string, content: JSX.Element, imageSrc: string) => (
    <div className="mb-20">
      <h2 className="text-2xl text-center mb-6 font-gothic">
        {title}
      </h2>
      <div
        className="bg-white px-4 sm:px-8 md:px-12 py-8 rounded-xl"
        style={{ border: '2px solid #000000' }}
      >
        <div className="flex flex-col-reverse md:flex-row items-center justify-between">
          {content}
          <img
            className="h-auto w-24 sm:w-28 md:w-32 relative overflow-hidden shrink-0 mb-4 md:mb-0 md:mr-4 pr-5"
            loading="lazy"
            alt=""
            src={imageSrc}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full">
      <main className="max-w-6xl mx-auto px-5 py-10">
        <section className="text-black font-gothic">
          {renderSection(t("developer"),
            <p className="text-base sm:text-lg md:text-xl leading-relaxed font-kosugi md:w-full">
              {t("oliveexplore")}
            </p>,
            "/Story_earth.svg"
          )}

          {renderSection("",
            <div className="flex flex-col font-kosugi md:w-full">
              <span className="text-base sm:text-lg md:text-xl leading-relaxed mb-6">{t("technology")}</span>
              <span className="text-base sm:text-lg md:text-xl leading-relaxed mb-6">{t("present")}</span>
              <span className="text-base sm:text-lg md:text-xl leading-relaxed mb-6">{t("remoteworking")}</span>
              <span className="text-base sm:text-lg md:text-xl leading-relaxed mb-6">{t("automotive")}</span>
              <span className="text-base sm:text-lg md:text-xl leading-relaxed">{t("collaborations")}</span>
            </div>,
            "/Story_motor.svg"
          )}
        </section>
      </main>
    </div>
  );
};

export default Story;