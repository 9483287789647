import React, { useEffect, useState } from 'react';
import { motion, TargetAndTransition } from 'framer-motion';

interface Circle {
  id: number;
  x: number;
  y: number;
  size: number;
  color: string;
}

const BackgroundAnimation: React.FC = () => {
  const [circles, setCircles] = useState<Circle[]>([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const pastelColors = [
    'rgba(183, 255, 183, 0.3)',  // パステル緑（透明度30%）
    'rgba(183, 219, 255, 0.3)',  // パステル青（透明度30%）
    'rgba(255, 183, 183, 0.3)',  // パステル赤（透明度30%）
    'rgba(255, 255, 184, 0.3)'   // パステル黄（透明度30%）
  ];

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    const initialCircles = Array.from({ length: 15 }, (_, i) => ({
      id: i,
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      size: Math.random() * 100 + 50,
      color: pastelColors[Math.floor(Math.random() * pastelColors.length)],
    }));
    setCircles(initialCircles);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateRandomMovement = (): TargetAndTransition => {
    return {
      x: Math.random() * dimensions.width,
      y: Math.random() * dimensions.height,
      transition: {
        duration: Math.random() * 10 + 5,
        ease: "linear",
        repeat: Infinity,
        repeatType: "mirror" as const,
      },
    };
  };

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      {circles.map((circle) => (
        <motion.div
          key={circle.id}
          className="absolute rounded-full"
          style={{
            width: circle.size,
            height: circle.size,
            backgroundColor: circle.color,
          }}
          initial={{ x: circle.x, y: circle.y }}
          animate={generateRandomMovement()}
        />
      ))}
    </div>
  );
};

export default BackgroundAnimation;