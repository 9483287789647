import React from 'react';
import '../styles/index.css';

const Footer: React.FC = () => {

  return (
        <footer className="w-full bg-darkslategray text-white font-abeezee border-b-2 border-darkslategray font-gothic">
          <div className="container mx-auto flex justify-center items-center">
            <p className="text-sm whitespace-normal text-center">
              © Olive All Rights Reserved.
            </p>
          </div>
        </footer>
      );
}

export default Footer;
