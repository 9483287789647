import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import './styles/index.css';
import AboutOlive from './pages/AboutOlive';
import AboutUs from './pages/AboutUs';
import Company from './pages/Company';
import Contact from './pages/Contact';
import Pressrelease from './pages/Pressrelease';
import Recruit from './pages/Recruit';
import Story from './pages/Story';
import Top from './pages/Top';
import Footer from './components/Footer';
import Header from './components/Header';
import BackgroundAnimation from './components/BackgroundAnimation';

// GA4の初期化
ReactGA.initialize('G-W9T10KRJ0Y'); // あなたの測定IDに置き換えてください

const AppContent: React.FC = () => {
  const location = useLocation();
  const isTopPage = location.pathname === '/';
  const { i18n } = useTranslation();

  useEffect(() => {
    // ページビューの送信
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
    const userLanguage = navigator.language.split('-')[0];
    const language = userLanguage === 'ja' ? 'ja' : 'en';
    i18n.changeLanguage(language);
    document.documentElement.lang = i18n.language;
  }, [i18n]);

  return (
    <div className="flex flex-col min-h-screen bg-white relative overflow-hidden">
      {!isTopPage && <BackgroundAnimation />}
      <div className="relative z-10">
        <Header />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/company" element={<Company />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pressrelease" element={<Pressrelease />} />
            <Route path="/recruit" element={<Recruit />} />
            <Route path="/story" element={<Story />} />
            <Route path="/aboutolive" element={<AboutOlive />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
}

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;