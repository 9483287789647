import { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';

const AboutUs: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const renderSection = (title: string, content: JSX.Element, imageSrc: string | null) => (
    <div className="mb-20">
      <h2 className="text-2xl text-center mb-6">
        {title}
      </h2>
      <div
        className="bg-white px-4 sm:px-8 md:px-12 py-8 rounded-xl"
        style={{ border: '2px solid #000000' }}
      >
        <div className="flex flex-col md:flex-row items-center justify-between">
          {imageSrc && (
            <img
              className="h-auto w-24 sm:w-28 md:w-32 relative overflow-hidden shrink-0 mb-4 md:mb-0 md:mr-4 pr-5"
              loading="lazy"
              alt=""
              src={imageSrc}
            />
          )}
          {content}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full">
      <main className="max-w-6xl mx-auto px-5 py-10">
        <section className="text-black font-gothic">
          {renderSection(t("aboutus"), 
            <p className="text-base sm:text-lg md:text-xl leading-relaxed font-kosugi md:w-full">
              {t("create")}
            </p>,
            "/Olive.svg"
          )}

          {renderSection(t("keymarkets"), 
            <p className="text-base sm:text-lg md:text-xl leading-relaxed font-kosugi md:w-full">
              {t("focus")}
            </p>,
            "/Market_camera.svg"
          )}

          {renderSection("", 
            <p className="text-base sm:text-lg md:text-xl leading-relaxed font-kosugi md:w-full">
              {t("biometric")}
            </p>,
            "/Market_heart.svg"
          )}

          {renderSection(t("multimodal"), 
            <div className="flex flex-col font-kosugi md:w-full">
              <span className="text-base sm:text-lg md:text-xl leading-relaxed mb-6">
              {t("utilizingmultimodal")}
              </span>
              <span className="text-base sm:text-lg md:text-xl leading-relaxed">
              {t("employs")}
              </span>
            </div>,
            "/Multimodal.svg"
          )}

          {renderSection(t("kokorokubari"), 
            <p className="text-base sm:text-lg md:text-xl leading-relaxed font-kosugi md:w-full">
            {t("communication")}
            </p>,
            "/Lacause.svg"
          )}

          {renderSection(t("lacause"), 
            <p className="text-base sm:text-lg md:text-xl leading-relaxed md:w-full">
              <img
                src={i18n.language === 'ja' ? "/aboutus_technology_fig1.svg" : "/aboutus_technology_fig1_en.svg"}
                alt="La Cause Technology"
                className="w-full h-auto"
              />
            </p>,
            null
          )}

          {renderSection(t("NumberofClients"), 
            <p className="text-base sm:text-lg md:text-xl leading-relaxed md:w-full">
              <img
                src={i18n.language === 'ja' ? "/client_ja.png" : "/client_en.png"}
                alt="La Cause Technology"
                className="w-full h-auto"
              />
            </p>,
            null
          )}
        </section>
      </main>
    </div>
  );
};

export default AboutUs;