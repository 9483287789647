import { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';

const Top: FunctionComponent = () => {
  const { t,i18n } = useTranslation();

  const getImageSrc = (baseName: string) => {
    const lang = i18n.language;
    return lang === 'en' ? `/${baseName}_en.svg` : `/${baseName}.svg`;
  };

  return (
    <div className="w-full min-h-screen bg-gray flex flex-col items-center justify-center">
      <section className="self-stretch flex flex-col items-center justify-center pt-0 px-0 box-border max-w-full text-center text-6xl text-black font-roboto">
        <div className="w-full mx-auto px-5 box-border">
          <div className="w-full flex flex-col items-center gap-10">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-[60px] mt-20 text-center font-gothic">
            {t("olive")}
            </h1>
            
            <div className="w-full flex flex-col items-center justify-start pt-0 px-1 sm:px-2 md:px-4 lg:px-6 pb-[260px] gap-[20px] mq450:gap-6 mq750:pb-[169px] mq750:box-border text-center min-h-[228px] text-base sm:text-lg md:text-xl lg:text-2xl justify-center font-kosugi">
              <p className="m-0">{t("powerofemotion")}</p>
              <p className="m-0">&nbsp;</p>
              <p className="m-0">{t("heartofeverything")}</p>
              <p className="m-0">{t("createtechnology")}</p>
              <p className="m-0">&nbsp;</p>
              <p className="m-0">{t("evolving")}</p>
            </div>
            <div className="w-full flex flex-col items-center justify-start py-0 px-5 text-center">
              <div className="w-full max-w-[600px] flex flex-col items-center justify-start gap-9 mq450:gap-[18px]">
                <div className="w-full text-lg sm:text-xl md:text-2xl lg:text-3xl font-kosugi">
                  {t("measuring")}
                </div>
                <div className="w-full flex justify-center">
                  <img
                    className="h-auto w-full max-w-[120px] sm:max-w-[150px] md:max-w-[181px]"
                    loading="lazy"
                    alt=""
                    src="/19261-color-1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <img
          className="w-full h-auto max-w-[1000px] mt-80"
          loading="lazy"
          alt=""
          src={getImageSrc("Topfirst_sent")}
        />
        <img
          className="w-full h-auto max-w-[1000px] mt-80"
          loading="lazy"
          alt=""
          src={getImageSrc("Topsecond_sent")}
        />
        <img
          className="w-full h-auto max-w-[1000px] mt-80"
          loading="lazy"
          alt=""
          src={getImageSrc("Topthird_sent")}
        />
        <img
          className="w-full h-auto max-w-[1000px] mt-80"
          loading="lazy"
          alt=""
          src={getImageSrc("Topend_sent")}
        />

        <div className="flex justify-center my-10">
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="flex items-center bg-gray text-black px-4 py-2 rounded-full hover:bg-white transition duration-300 border border-gray-200"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
            <span className="transition-colors duration-300 hover:text-gray-700">{t("back")}</span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Top;