import { FunctionComponent, useState, FormEvent } from "react";
import {
  TextField,
  Button,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from 'react-i18next';

export type FormType = {
  className?: string;
};

const Form: FunctionComponent<FormType> = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    return formData.lastName.trim() !== "" &&
           formData.firstName.trim() !== "" &&
           formData.email.trim() !== "" &&
           formData.message.trim() !== "";
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormValid()) {
      const formDataToSend = new FormData();
      formDataToSend.append("entry.748002797", formData.lastName);
      formDataToSend.append("entry.1215744288", formData.firstName);
      formDataToSend.append("entry.862183993", formData.email);
      formDataToSend.append("entry.1922992977", formData.phone);
      formDataToSend.append("entry.845137901", formData.message);
  
      fetch("https://docs.google.com/forms/u/0/d/e/1FAIpQLSe6wVBsKgnlOSu99fQv3t6hFY4JZClGVfRN_Wu3HKZiBi7WSQ/formResponse", {
        method: "POST",
        body: formDataToSend,
        mode: "no-cors"
      }).then(() => {
        setIsModalOpen(true);
      }).catch(error => console.error("エラー:", error));
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const { t } = useTranslation();

  return (
    <section
      className={`w-[1090px] rounded-xl flex flex-col items-center justify-start pt-[15px] px-5 pb-5 box-border gap-[37px] max-w-full text-left text-mini text-black font-gothic ${className}`}
    >
      <div className="w-[512px] flex flex-col items-start justify-start gap-3.5 max-w-full">
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 text-6xl">
          <p className="relative text-[inherit] z-[1] font-bold">
          {t("contact")}
          </p>
        </div>
        <form onSubmit={handleSubmit} className="self-stretch flex flex-col items-start justify-start gap-3.5 max-w-full">
          <TextField
            label={t("lastname")}
            name="lastName"
            variant="outlined"
            fullWidth
            value={formData.lastName}
            onChange={handleInputChange}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "62px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                color: "#000",
              },
            }}
          />
          <TextField
            label={t("firstname")}
            name="firstName"
            variant="outlined"
            fullWidth
            value={formData.firstName}
            onChange={handleInputChange}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "62px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                color: "#000",
              },
            }}
          />
          <TextField
            label={t("email")}
            name="email"
            variant="outlined"
            fullWidth
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "62px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                color: "#000",
              },
            }}
          />
          <TextField
            label={t("telephonenumber")}
            name="phone"
            variant="outlined"
            fullWidth
            type="tel"
            value={formData.phone}
            onChange={handleInputChange}
            // required属性を削除
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "62px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                color: "#000",
              },
            }}
          />
          <TextField
            label={t("message")}
            name="message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            value={formData.message}
            onChange={handleInputChange}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                color: "#000",
              },
            }}
          />
          <div className="w-[512px] flex flex-row items-start justify-center py-0 pl-0 pr-0.5 box-border max-w-full">
            <Button
              type="submit"
              className="h-[62px] w-[278px]"
              disableElevation
              variant="contained"
              disabled={!isFormValid()}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "24px",
                background: "#3b3c36",
                borderRadius: "40px",
                "&:hover": { 
                  background: "#808080",
                  opacity: 0.8,
                  transition: "all 0.3s ease-in-out"
                },
                "&:disabled": { background: "#e6e6e6", color: "#999999" },
                width: 278,
                height: 62,
              }}
            >
              {t("submit")}
            </Button>
          </div>
        </form>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("submissiocompleted")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t("submitmessage")}
          </Typography>
          <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
            {t("close")}
          </Button>
        </Box>
      </Modal>
    </section>
  );
};

export default Form;