import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLang = localStorage.getItem('language');
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'ja' : 'en';
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  return (
    <span 
      onClick={toggleLanguage}
      className="cursor-pointer hover:text-gray-300 transition-colors duration-300"
      title={i18n.language === 'en' ? '日本語に切り替え' : 'Switch to English'}
    >
      <img
        src="/translation.svg"
        alt="言語切り替え"
        width={24}
        height={24}
      />
    </span>
  );
};

export default LanguageSwitcher;