import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import '../styles/index.css';
import { useTranslation } from 'react-i18next';

const DropdownButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getLocalizedUrl = (jaUrl: string, enUrl: string) => {
    return i18n.language === 'ja' ? jaUrl : enUrl;
  };
  
  return (
    <div className="relative">
      <Button 
        size="large" 
        startIcon={isOpen ? <CaretRightOutlined /> : <CaretLeftOutlined />}
        sx={{ backgroundColor: 'transparent', color: 'white' }}
        onClick={toggleDropdown}
      >
      </Button>
      {isOpen && (
        <div className="!border-8 !border-black absolute top-0 right-full bg-antiquewhite mr-4 py-2 rounded-md z-50">
          <a href="/" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black text-lg font-bold">{t("toppage")}</a>
          <a href="/aboutolive" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black text-lg font-bold">{t("aboutolive")}</a>
          <a href="/aboutus" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black flex items-center"><span className="w-2 h-2 bg-black rounded-full mr-2"></span>{t("aboutus")}</a>
          <a href="/company" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black flex items-center"><span className="w-2 h-2 bg-black rounded-full mr-2"></span>{t("company")}</a>
          <a href="/story" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black flex items-center"><span className="w-2 h-2 bg-black rounded-full mr-2"></span>{t("developer")}</a>
          <a href="/pressrelease" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black flex items-center"><span className="w-2 h-2 bg-black rounded-full mr-2"></span>{t("news")}</a>
          <a href={getLocalizedUrl("https://lite.demo.01ive.co.jp", "https://lite-en.demo.01ive.co.jp/")} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black flex items-center"><span className="w-2 h-2 bg-black rounded-full mr-2"></span>{t("secret")}</a>
          <a href="/recruit" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black flex items-center"><span className="w-2 h-2 bg-black rounded-full mr-2"></span>{t("careers")}</a>
          <a href="/contact" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 border-b border-black flex items-center"><span className="w-2 h-2 bg-black rounded-full mr-2"></span>{t("contact")}</a>
          <a href={getLocalizedUrl("https://map.01ive.co.jp", "https://map.01ive.co.jp")} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-black hover:bg-zinc-200 whitespace-nowrap no-underline hover:text-gray-600 transition-colors duration-300 flex items-center"><span className="w-2 h-2 bg-black rounded-full mr-2"></span>{t("map")}</a>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;