import { FunctionComponent } from "react";
import Form from "../components/Form";
const Contact: FunctionComponent = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow flex items-center justify-center py-0">
        <Form />
      </main>
    </div>
  );
};

export default Contact;
